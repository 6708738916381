import React from 'react';

import Layout from '../components/Layout';

import donate_button_img from '../assets/images/donate-button.png';

const IndexPage = () => (
  <Layout pageHeader={true} menuBar={true}>
    <article id="main">
      <header>
        <h2>Donation Page</h2>
        <p>Click on the PayPal button to donate to Voodoo Shipping Company</p>
      </header>
        <div className="inner">
          <form style={{backgroundColor: '#0b1743', width: '25%', margin: '0px auto 100px'}} action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
            <table>
              <tr><td><input type="hidden" name="on0" value="(Optional) Discord Username:" />(Optional) Discord Username</td></tr><tr><td><input type="text" name="os0" maxlength="64" /></td></tr>
              <tr><td><input type="hidden" name="on1" value="(Optional) PS2 Character:" />(Optional) PS2 Character</td></tr><tr><td><input type="text" name="os1" maxlength="64" /></td></tr>
            </table>
            <input type="image" src={donate_button_img} border="0" width="100%" name="submit" title="Donate with PayPal" alt="Donate" />
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            <input type="hidden" name="business" value="5YYB9UPWP2N3G" />
            <input type="hidden" name="bn" value="5YYB9UPWP2N3G:PP-BuyNowBF_S" />
            <input type="hidden" name="cmd" value="_donations" />
            <input type="hidden" name="button_subtype" value="donation" />
            <input type="hidden" name="enable_hosted_buttons" value="false" />
            <input type="hidden" name="item_name" value="VCO Community Contributions" />
            <input type="hidden" name="buy_now_text" value="Donate with PayPal" />
            <input type="hidden" name="no_recurring" value="1" />
            <input type="hidden" name="inventory_overridden" value="true" />
            <input type="hidden" name="track_pnl" value="false" />
            <input type="hidden" name="trackByItem" value="true" />
            <input type="hidden" name="track_inventory" value="false" />
            <input type="hidden" name="currency_code" value="USD" />
            <input type="hidden" name="no_shipping" value="1" />
            <input type="hidden" name="no_note" value="0" />
            <input type="hidden" name="cn" value="(Optional) Enter Discord Username" />
          </form>
        </div>
    </article>
  </Layout>
);

export default IndexPage;
